<template>
  <a-modal
    title="Actualizar día feriado"
    :show="show"
    @confirm="update"
    @cancel="$emit('cancel')">

    <div class="space-y-4 mt-4">
      <a-input
        id="create_name"
        @input="clear('name')"
        :error="error('name')"
        label="Nombre"
        v-model="form.name" />

      <a-input
        id="create_name"
        label="Fecha"
        type="date"
        @input="clear('date')"
        :error="error('date')"
        v-model="form.date" />

      <a-select
        :source="periods"
        id="create_period"
        label="Período"
        @input="clear('period_id')"
        :error="error('period_id')"
        :loading="loadings.index"
        v-model="form.period_id" />
    </div>
  </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from '../../filters/moment'
export default {
  props: {
    show: { required: true, type: Boolean },
    holiday: { required: false, type: Number, default: null }
  },
  data: () => ({
    form: {
      name: '',
      date: '',
      period_id: null
    },
    periods: [],
    loadings: {
      update: false,
      index: false
    },
  }),
  computed: mapGetters({
    error: 'error'
  }),
  watch: {
    show(val) {
      if (val) {
        this.fetch()
      }
    }
  },
  methods: {
    ...mapActions({
      'clear': 'removeError'
    }),
    fetch() {
      this.loadings.index = !this.loadings.index

      const requests = [
        this.$repository.periods.index({ limit: 0 }),
        this.$repository.holidays.show(this.holiday)
      ]

      Promise.all(requests)
          .then(([{ data: periods }, holiday]) => {
            this.periods = periods.map(period => ({ label: `${period.year}-${period.number}`, value: period.id }))
            this.form = {
              ...holiday,
              date: moment(holiday.date, 'YYYY-MM-DD', '', true)
            }
            delete this.form.created_at
            delete this.form.updated_at
          })
          .finally(() => this.loadings.index = !this.loadings.index)
    },
    update() {
      this.loadings.update = !this.loadings.update
      this.$repository.holidays
        .update(this.holiday, this.form)
        .then(() => {
          this.form = { name: '', date: '', period_id: null }
          this.$emit('update')
          this.$emit('cancel')
        })
        .catch(() => this.loadings.update = !this.loadings.update)
    }
  }
}
</script>
