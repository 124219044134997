<template>
  <a-modal
    title="Crear día feriado"
    :show="show"
    @confirm="store"
    @cancel="$emit('cancel')">

    <div class="space-y-4 mt-4">
      <a-input
        id="create_name"
        @input="clear('name')"
        :error="error('name')"
        label="Nombre"
        v-model="holiday.name" />

      <a-input
        id="create_name"
        label="Fecha"
        type="date"
        @input="clear('date')"
        :error="error('date')"
        v-model="holiday.date" />

      <a-select
        :source="periods"
        id="create_period"
        label="Período"
        @input="clear('period_id')"
        :error="error('period_id')"
        :loading="loadings.index"
        v-model="holiday.period_id" />
    </div>
  </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    show: { required: true, type: Boolean }
  },
  data: () => ({
    holiday: {
      name: '',
      date: '',
      period_id: null
    },
    periods: [],
    loadings: {
      store: false,
      index: false
    },
  }),
  computed: mapGetters({
    error: 'error'
  }),
  watch: {
    show(val) {
      if (val) {
        this.fetch()
      }
    }
  },
  methods: {
    ...mapActions({
      'clear': 'removeError'
    }),
    fetch() {
      this.loadings.index = !this.loadings.index
      this.$repository.periods
        .index({ limit: 0 })
        .then(({ data: periods }) => {
          this.periods = periods.map(period => ({ label: `${period.year}-${period.number}`, value: period.id }))
        })
        .finally(() => this.loadings.index = !this.loadings.index)
    },
    store() {
      this.loadings.store = !this.loadings.store
      this.$repository.holidays
        .store(this.holiday)
        .then(() => {
          this.holiday = { name: '', date: '', period_id: null }
          this.$emit('store')
          this.$emit('cancel')
        })
        .catch(() => this.loadings.store = !this.loadings.store)
    }
  }
}
</script>
