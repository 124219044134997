<template>
  <div class="p-1">
    <div class="flex flex-wrap justify-between items-center mb-2">
      <h3 class="text-2xl">
        Días feriados
      </h3>
      <a-button v-if="user.isAdmin" @click="modals.create = true" class="w-full md:w-auto">
        Crear día feriado
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :source="pagination.data"
      :loading="loadings.index"
      :pagination="pagination"
      @paginate="search({ page: $event })"
      toolbar-column-filter
      @toolbar(refresh)="search({ page: 1 })">

      <template #toolbar(search)>
        <a-input
            is-search
            placeholder="Buscar..."
            @search="search({ search: $event, page: 1 })" />
      </template>

      <template #td(actions)="{ item }">
        <a-dropdown>
          <template #menu>
            <a-dropdown-item @click="modals.update = item.id">
              Actualizar
            </a-dropdown-item>
            <a-dropdown-item @click="remove(item.id)">
              Eliminar
            </a-dropdown-item>
          </template>
        </a-dropdown>
      </template>
    </a-table>

    <create-holiday-modal
      :show="modals.create"
      @store="search"
      @cancel="modals.create = false" />

      <update-holiday-modal
        :show="modals.update !== null"
        :holiday="modals.update"
        @update="search"
        @cancel="modals.update = null" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CreateHolidayModal from './create-holiday.vue'
import UpdateHolidayModal from './update-holiday.vue'
import moment from '../../filters/moment'
export default {
  components: {
    UpdateHolidayModal,
    CreateHolidayModal
  },
  data: () => ({
    query: {
      limit: 10,
      search: '',
      with: 'period',
      sort_by: 'name,asc'
    },
    modals: {
      create: false,
      update: null
    }
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      pagination: state => state.holidays.pagination,
      loadings: state => state.holidays.loadings
    }),
    ...mapGetters(['lang']),
    columns() {
      return [
        { title: 'Nombre', key: 'name', display: true },
        { title: 'Fecha', key: 'date', mutate: ({ key }) => moment(key, 'YYYY-MM-DD', '', true), display: true },
        { title: 'Período', key: 'period', mutate: ({key: period}) => `${period.year}-${period.number}`, display: true },
        { title: 'Acciones', key: 'id', slot: 'actions', alignment: 'right', columnFilterable: false, display: this.user.isAdmin },
      ].filter($0 => $0.display)
    }
  },
  methods: {
    ...mapActions({
      fetch: 'holidays/index',
      remove: 'holidays/delete'
    }),
    search(query = {}) {
      this.fetch({ ...this.query, ...query })
    }
  },
  mounted() {
    this.search({ page: this.$route.query.page || 1 })
  }
}
</script>
